import findIndex from "lodash/findIndex";
import filter from "lodash/filter";
import last from "lodash/last";

export default {
    addSavedAnswer(state, payload) {
        if (!state.submission) {
            return;
        }

        const index = findIndex(state.submission.answers, { id: payload.answer.id });

        if (index > -1) {
            state.submission.answers[index] = payload.answer;
        } else {
            state.submission.answers.push(payload.answer);
        }
    },

    deleteSavedAnswer(state, payload) {
        if (!state.submission) {
            return;
        }

        const newList = filter(state.submission.answers, (answer) => answer.id !== payload.answer.id);

        state.submission.answers = newList;
    },

    navigating(state, value) {
        state.navigating = value;
    },

    setAnswerScore(state, payload) {
        const { answerId, newScore } = payload;

        const index = findIndex(state.submission.answers, { id: answerId });

        state.submission.answers[index].score = newScore;
        state.submission.answers[index].teacher_feedback_provided = payload.teacher_feedback_provided;
    },

    setCorrectAnswers(state, value) {
        state.correctAnswers = value;
    },

    setGraded(state, value) {
        state.sidebarOpen = value;
        state.graded = value;
    },

    setPreview(state, payload) {
        state.preview = payload;
    },

    setReseting(state, payload) {
        const { clientId, reseting } = payload;

        state.questionsReseting[clientId] = reseting;
    },

    setSample(state, payload) {
        state.sample = payload;
    },

    setSaving(state, payload) {
        const { clientId, saving } = payload;

        state.saving = saving;
    },

    setSecret(state, payload) {
        state.secret = payload;
    },

    setUser(state, payload) {
        state.user = payload;
    },

    toggleSidebar(state, payload) {
        state.sidebarOpen = !state.sidebarOpen;
    },

    updatePageNumber(state, payload) {
        state.page.order += payload;
    },

    updatePage(state, payload) {
        state.page = payload;
    },

    updatePages(state, payload) {
        const pageIndex = payload.findIndex((page) => page.order === "review");

        if (!state.preview && !state.graded && pageIndex === -1) {
            payload.push({
                id: null,
                order: last(payload).order + 1,
                isReview: true,
                questions: [],
            });
        }

        state.pages = payload;
    },

    updateSubmission(state, payload) {
        state.submission = { ...payload };
    },

    setResource(state, payload) {
        state.resource = payload;
    },
};
