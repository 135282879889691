import axios from "axios";
import { router, usePage } from "@inertiajs/vue3";

const page = usePage();

export default {
    changePage({ commit, dispatch, getters }, payload) {
        commit("navigating", true);
        const params = { test: getters.page.test_id, page: getters.page.order + payload };
        let routeName = "tests.show.page";
        if (getters.isSample) {
            routeName = "sample-test.page";
            params["resource"] = getters.resource.id;
        } else if (getters.isPreview) {
            routeName = "tests.preview.page";
        } else if (getters.graded) {
            routeName = "teacher.submission.page";
            params["submission"] = getters.submission.id;
            delete params["test"];
        }

        const url = route(routeName, params);

        // If coming from review page, redirect to force refresh
        if (window.location.href.includes("/review")) {
            window.location = url;
            return;
        }

        // Use Inertia router to navigate to the page
        router.get(
            url,
            {},
            {
                preserveState: true,
                only: ["page"],
                onSuccess: (page) => {
                    commit("updatePage", page.props.page);
                },
                onFinish: () => {
                    commit("navigating", false);
                },
            },
        );
    },

    changeScore({ getters, commit }, payload) {
        const { questionId, answerId, newScore } = payload;
        const { submission } = getters;

        const routeParams = { submission: getters.submission.id };
        if (answerId) {
            routeParams.answer = answerId;
        }

        const url = route("grade.submission.answer", routeParams);

        const params = {
            newScore,
            questionId,
        };

        return axios
            .patch(url, params)
            .then(({ data }) => {
                commit("setAnswerScore", { ...payload, ...data });
            })
            .catch((errors) => {
                console.error(errors);
            });
    },

    createSubmission({ getters, commit }) {
        if (!getters.isPreview) {
            const testId = page.props.test.id;
            return axios
                .get(route("test.submission", { test: testId }))
                .then(({ data }) => {
                    commit("updateSubmission", data);

                    return data;
                })
                .catch(({ response }) => {
                    console.error("Failed to get or create submission due to invalid code or test!");
                    if (response?.status === 400 && response?.data.message) {
                        alert(response?.data.message);
                        window.location.href = route("student-logout");
                    }

                    return { error: response?.data.message };
                });
        }
    },

    getCorrectAnswers({ commit, getters, state }) {
        axios
            .get(route("test.correct-answers", { test: getters.testId, secret: state.secret }))
            .then(({ data }) => {
                commit("setCorrectAnswers", data);
            })
            .catch((errors) => {
                console.error(errors);
            });
    },

    goToReview({ commit, getters }) {
        commit("navigating", true);
        router.get(
            route("tests.review", { test: getters.submission.test_id }),
            {},
            {
                onFinish: () => {
                    commit("updatePageNumber", getters.numberOfPages - getters.pageNumber + 1);
                    commit("navigating", false);
                },
            },
        );
    },

    navigateToPage({ dispatch, getters }, payload) {
        if (payload !== getters.pageNumber) {
            const page = _.find(getters.pages, { order: payload });

            if (page && _.get(page, "isReview", false)) {
                return dispatch("goToReview");
            }

            return dispatch("changePage", payload - getters.pageNumber);
        }
        return new Promise((resolve) => {
            resolve();
        });
    },

    resetAnswer({ getters, commit }, payload) {
        const { clientId } = payload;
        const answer = getters.submittedAnswer(clientId);

        commit("setReseting", { clientId, reseting: true });

        if (getters.isPreview || answer === undefined) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    commit("setReseting", { clientId, reseting: false });
                    resolve(payload);
                }, 1000);
            });
        }
        const url = route("submission.answer.reset", { submission: getters.submission.id, answer: answer.id });

        return axios
            .delete(url, payload)
            .then((response) => {
                const { data } = response;

                commit("deleteSavedAnswer", { answer });
                commit("setReseting", { clientId, reseting: false });

                return response;
            })
            .catch((errors) => {
                // If it returns a 422 status, it means the test has been submitted.
                if (errors.status === 422) {
                    alert(
                        "Reset answer failed. Please make sure you have not submitted the test. For more information, please contact your instructor.",
                    );
                    window.location.reload(); // Force refresh page to redirect
                    return null;
                }

                return errors;
            });
    },

    saveAnswer({ getters, commit }, payload) {
        const { clientId } = payload;

        if (getters.isPreview) {
            commit("setSaving", { clientId, saving: true });

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    commit("setSaving", { clientId, saving: false });
                    resolve(payload);
                }, 1000);
            });
        }

        if (getters.testSubmitted) {
            alert("Sorry, you can no longer make changes to your answers.");
            window.location.reload(); // Force refresh page to redirect
            return null;
        }
        // Submission is created when student started the test.
        // If for some reason it's not created, we can't save the answer.
        if (getters.submission === null || !getters.submission.id) {
            alert("Failed to save answer. Please refresh the page and try again.");
            return null;
        }

        commit("setSaving", { clientId, saving: true });
        const url = route("submission.answer", { submission: getters.submission.id });

        return axios
            .post(url, payload)
            .then((response) => {
                const { data } = response;

                // Check for server error
                if (data.error && data.message) {
                    alert(`Failed to save answer. ${data.message}`);
                    location.reload(); // Force refresh page to redirect
                    return response;
                }

                commit("addSavedAnswer", { answer: data });
                commit("setSaving", { clientId, saving: false });

                return response;
            })
            .catch((errors) => {
                // If it returns a 422 status, it means the test has been submitted.
                if (errors.status === 422) {
                    alert(
                        "Save answer failed. Please make sure you have not submitted the test. For more information, please contact your instructor.",
                    );
                    window.location.reload(); // Force refresh page to redirect
                    return null;
                }

                return errors;
            });
    },

    updateLastCompletedPage({ state }, payload) {
        state.lastCompletedPage = payload;
    },
};
